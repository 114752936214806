import icon, { iconsMap } from './Icon';
import button from './Button';

export default markRaw({
  name: 'cmpSection',

  props: {
    textColor: String,
    statusButton: {
      type: Boolean,
      default: false,
    },
    statusIcon: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value),
    },
    actionIcon: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value),
    },
  },

  emits: ['statusClick', 'actionClick'],
  setup(props, { slots, emit }) {
    const sectionClasses = computed(() => {
      const classString = 'cmp-section';
      return classString;
    });

    const textClasses = computed(() => {
      let classString = 'cmp-section__title truncate';
      if (props.textColor) {
        classString += ` text-${props.textColor}`;
      }

      return classString;
    });

    return () => {
      const children = [];
      if (slots.default) {
        children.push(h('div', { class: textClasses.value }, slots.default()));
      }

      let status;
      if (slots.status) status = slots.status();
      else if (!props.statusButton) status = h(icon, { class: 'm-[2px]', name: props.statusIcon ?? 'plus', style: { 'font-size': '16px' } });
      else {
        status = h(button, {
          icon: props.statusIcon,
          size: 'sm',
          onClick: () => emit('statusClick'),
        });
      }

      children.push(h('div', { class: 'cmp-section__status' }, status));

      if (props.actionIcon) {
        children.push(h(button, {
          icon: props.actionIcon,
          class: 'cmp-section__action',
          onClick: () => emit('actionClick'),
        }));
      }

      return h('div', {
        class: sectionClasses.value,
      }, children);
    };
  },
});
