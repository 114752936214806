import { Orientation } from '@/common/enums';

const aspectRatios = {
  '21:9': { width: 210, height: 90 },
  '2:1': { width: 200, height: 100 },
  '16:9': { width: 160, height: 90 },
  '4:3': { width: 133, height: 100 },
  '1:1': { width: 100, height: 100 },
};

export const getArtBoardSize = (ori: Orientation, ratio = '2:1') => {
  let width: number;
  let height: number;

  if (aspectRatios[ratio]) {
    ({ width, height } = aspectRatios[ratio as keyof typeof aspectRatios]);
  } else {
    const [w, h] = ratio.split(':').map(Number);
    if (isNaN(w) || isNaN(h)) {
      throw new Error('Invalid custom ratio');
    }
    width = Math.max(w, h);
    height = Math.min(w, h);
  }

  switch (ori) {
    case Orientation.LANDSCAPE:
      return { width, height };
    case Orientation.PORTRAIT:
    default:
      return { width: height, height: width };
  }
};

export default getArtBoardSize;
