import { defineStore } from 'pinia';

export const usePlayPreviewCanvasStore = defineStore('playPreviewCanvas', {
  state: () => ({
    pathviewPreviewActive: false, // Are we previewing?
    showPreview: true, // Can we see the preview?
    showPathView: true, // Can we see the path view?
    scenePosLookup: {},
    pathViewSceneCacheState: {},
    showURLPreviewDialog: false,
    externalUrlPreviewLinks: {
      iosUrl: '',
      androidUrl: '',
      onClickUrl: '',
      action: '',
    },
    connectedNodes: {} as { visible: boolean; toSceneId: string, x: number, y: number; },
    visibleRoute: {} as { from: string; to: string; fromId: string; toId: string },
    connections: {} as { [key: string]: Array<string> },
    scenePath: [] as Array<string | -1>[],
    audioTrigger: false,
    currentScene: '',
    globalTime: 0,
  }),

  actions: {
    triggerExternalURLPreview(givenUrls:
    {
      iosUrl: string | '',
      androidUrl: string | '',
      onClickUrl: string | '',
      action: string | '',

    }): void {
      this.externalUrlPreviewLinks = givenUrls;
      this.showURLPreviewDialog = true;
    },
  },
});

export default usePlayPreviewCanvasStore;
