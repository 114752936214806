import { defineStore } from 'pinia';
import { requests } from '@/common/plugins';
import { useCompanyStore } from '@/common/store/company';
import { removeFromAxiosCache, removeItemInArray, updateAxiosCache } from '@/common/utils';
import { Creative, CreativeGetOptions, CreativeTemplate } from '@/views/creatives/interfaces';
import { createQueryParams, creativeQueryParms } from '../utils/getQuery';

export const useTemplateStore = defineStore('template', {
  state: () => ({
    companyTemplates: [] as Creative[],
    craftsmanTemplates: [] as CreativeTemplate[],
    groupList: [],
    selectedTemplateId: '',
    selectedGroupId: null,
    totalItems: 0,
    query: { ...creativeQueryParms },
  }),

  getters: {
    currentTemplate(state) {
      return [...state.companyTemplates, ...state.craftsmanTemplates].find((n) => state.selectedTemplateId === n.id);
    },
    // loop over all groups checking creatives array to see if selectedTemplateId is in it
    currentGroup(state) {
      return state.groupList.find((group) => group.creatives.find((creative) => creative.id === state.selectedTemplateId)) || null;
    },
  },

  actions: {
    /**
     * Fetch templates from API and set to templates
     * store variable. If the param name exists, filter
     * received data by name.
     * @param args: Template options
     */
    async fetchTemplates(args: CreativeGetOptions): Promise<void> {
      const { currentCompanyId } = useCompanyStore();

      const params = ['includes=taxonomies', 'includes=product', 'includes=groups:id', 'includes=creator', ...createQueryParams(args)];
      const cacheRef = ['fetch-templates', currentCompanyId];

      if (args.type) {
        cacheRef.push(args.type);
      }

      if (args.folderId) {
        cacheRef.push(args.folderId);
      }

      if (args.name) {
        cacheRef.push(args.name.toLocaleLowerCase().replace(' ', '_'));
      }

      const result = await requests.get(
        `/creatives/templates?${params.join('&')}`,
        { id: cacheRef.join('-') },
      );

      this.companyTemplates = result.items;
      this.craftsmanTemplates = result.templates.map((t) => ({ ...t, isGlobal: true }));
      this.totalItems = result.total;
    },

    /**
     * Make a POST request to create a new template using
     * creative id and company id
     * @param data - TemplateCreate info
     * @returns Template id
     */
    async createTemplate(id: string, isGlobal: boolean): Promise<string> {
      const { currentCompanyId } = useCompanyStore();

      const body = { creativeId: id } as { creativeId: string; companyId?: string };

      if (!isGlobal) body.companyId = currentCompanyId;

      const result: CreativeTemplate = await requests.post(
        '/creatives/templates',
        body,
        {
          cache: {
            update: {
              [`fetch-templates-${currentCompanyId}`]: 'delete',
            },
          },
        },
      );

      return result.id;
    },

    /**
     * Make a PATCH request to update the current template name
     * and update the response value with current value.
     * @param id => Template id
     */
    async updateTemplateName(name: string, id: string, index: number, isGlobal: boolean, update = false) {
      const { currentCompanyId } = useCompanyStore();
      const list = isGlobal ? this.craftsmanTemplates : this.companyTemplates;

      const result = await requests.patch(
        `/creatives/${id}`,
        { name },
        {
          cache: {
            update: {
              [`fetch-templates-${currentCompanyId}`]: (cache, res) => {
                const array = isGlobal ? 'templates' : 'items';
                return updateAxiosCache(cache, res, array, id);
              },
            },
          },
        },
      );

      if (update) {
        list[index].name = result.name;
        list[index].updatedAt = result.updatedAt;
      }
    },

    async uploadThumbnail(id: string, isGlobal: boolean, file: File) {
      const { currentCompanyId } = useCompanyStore();
      const list = isGlobal ? this.craftsmanTemplates : this.companyTemplates;
      const index = list.findIndex((n) => n.id === id);

      const fileReader = new FileReader();

      const fileBody = await new Promise<string | ArrayBuffer>((resolve) => {
        const readFile = () => {
          const buffer = fileReader.result;
          resolve(buffer);
        };

        fileReader.addEventListener('load', readFile);
        fileReader.readAsArrayBuffer(file);
      });

      const response = await requests.post(
        `/creatives/${id}/thumbnail`,
        fileBody,
        {
          headers: {
            'Content-Type': file.type,
          },
          cache: {
            update: {
              [`fetch-creatives-${currentCompanyId}`]: 'delete',
            },
          },
        },
      );

      list[index].coverImageS3Url = response.url;
    },

    /**
     * Make a DELETE request to remove a template from
     * specific list
     * @param id - Template id
     * @param isGlobal - Specific list
     */
    async deleteTemplate(id: string, isGlobal: boolean): Promise<void> {
      const { currentCompanyId } = useCompanyStore();
      const cacheList = isGlobal ? 'templates' : 'items';
      const list = isGlobal ? 'craftsmanTemplates' : 'companyTemplates';

      await requests.delete(
        `/creatives/${id}`,
        {
          cache: {
            update: {
              [`fetch-templates-${currentCompanyId}`]: (cache) => removeFromAxiosCache(cache, cacheList, id),
            },
          },
        },
      );

      // Remove in array
      this[list] = removeItemInArray(this[list], id) as CreativeTemplate[];
    },
  },
});

export default useTemplateStore;
