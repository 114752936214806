import axios from 'axios';
import { defineStore } from 'pinia';
import { ProductTypes } from '@/common/enums';
import { FileType } from '@/views/assets/enums';
import { RequestError } from '@/common/interfaces';
import { i18n, requests, api } from '@/common/plugins';
import { updateAssetsCache } from '@/views/assets/utils';
import { fetchAsset as fetchAssetWorker } from '@/common/workers';
import { INITIAL_DIALOG_SETUP } from '@/views/assets/constants/initialDialogSetup';
import { buildPathFromArray, removeFromAxiosCache, removeItemInArray, sortItems } from '@/common/utils';
import { Asset, AssetDialogConfig, AssetFolder, AssetFolderCheck, AssetOptimizeProgress, AssetProp, AssetUploadBody, AssetUploadResponse, LibraryGetOptions } from '@/views/assets/interfaces';
import { AssetGetOptions } from '@/views/creatives/interfaces';
import { assetQueryParms, createQueryParams } from '../utils/getQuery';

const { t } = i18n.global;

let uploading: string[] = [];
let loaded: string[] = [];
let failed: string[] = [];
let canceled = false;

fetchAssetWorker.onmessage = (msg) => {
  const { status, id } = msg.data;
  if (status === 'loaded') loaded.push(id);
  if (status === 'failed') failed.push(id);
};

export const useAssetsStore = defineStore('assets', {
  state: () => ({
    // Asset library id (Company - Craftsman+)
    libraryId: null,
    // Library data
    library: [] as Asset[],
    folders: [] as AssetFolder[],
    assetType: '',
    currentFolder: { id: null } as Partial<AssetFolder>,
    folderHistory: [] as AssetFolder[],
    folderNestedIndex: 0,
    cutAssets: [], // the cut item to be used for pasting
    currentlySelectedAssets: [] as Array<Asset | AssetFolder>,
    lastSelectedAsset: null,
    dialogOpen: false,
    renamingAsset: false,
    fileType: '',
    showEditDialog: false,
    optimizeAssets: true,
    // Teleport config
    dialogSettings: { ...INITIAL_DIALOG_SETUP },
    autoUpload: false,
    autoUploadFiles: null as FileList,
    productType: null,
    query: { ...assetQueryParms },
    totalItems: 0,
  }),

  getters: {
    isPlay: (state) => state.productType === ProductTypes.PLAY,
    isCanvas: (state) => state.productType === ProductTypes.CANVAS,

    /**
     * Check statsById data of current company and create an array of
     * CompanyInsight to be showed in companyEdit view.
     * @param state - Current state.
     * @returns - Array of CompanyInsight[]
     */
    getSelectedAssetsAlphabetically: (state) => {
      if (state.currentlySelectedAssets.length === 0) return [];
      // Sort alphabetically
      return sortItems(state.currentlySelectedAssets, { sortBy: 'name' });
    },

    /**
     * Returns asset library path according to componentState
     * and folderHistory items.
     * @param state - Current stare
     * @returns - Asset library in string format
     */
    assetLibraryPath: (state) => {
      const rootPath = t('ASSET_LIBRARY');
      const { componentState } = state.dialogSettings;

      if (componentState === 'editor') return rootPath;

      return buildPathFromArray(
        state.folderHistory.map((i) => i.name),
        rootPath,
      );
    },

    isCreateFolderActive: (state) => state.folders.some((f) => f.id === 'new_folder'),
  },

  actions: {
    /**
     * Set options values to dialogSettings to update AssetDialog
     * values and enable show status.
     * @param options - Dialog options
     */
    showAssetDialog(options: Partial<AssetDialogConfig>) {
      Object.assign(this.dialogSettings, {
        onCancel: () => {},
        ...options,
        show: true,
      });
    },

    /**
     * Reset dialogSettings with Initial config to close
     * AssetDialog component
     */
    hideAssetDialog() {
      Object.assign(this.dialogSettings, { ...INITIAL_DIALOG_SETUP });
    },

    /**
     * Make a GET request to get asset library.
     */
    async fetchAssetsLibrary(args: AssetGetOptions) {
      const cacheRef = ['fetch-asset-library', this.libraryId, this.currentFolder.id];
      const query = createQueryParams(args);

      const response = await requests.get(`/assets/library?${query.join('&')}`, { id: cacheRef.join('-') });

      if (args.page === 1) {
        this.totalItems = response.total;
        this.folders = response?.folders.map((n) => ({ ...n, isDirectory: true })) || [];
        this.library = response.items;
      }

      if (args.page > 1) {
        this.library.push(...response.items);
      }
    },

    /**
     * function to create a local empty folder
     * @param name => Specific folder name
     */
    addEmptyFolderItem(name?: string, folderId?: string): void {
      const { parentId } = this.useCommonLibraryValues();

      if ((folderId !== null) || parentId === folderId) {
        this.folders.unshift({
          id: 'new_folder',
          name: name || 'New Folder',
          parentId,
          isDirectory: true,
          createdAt: new Date(),
        });
      }
    },

    /**
     * Make a POST request to create a new asset folder, add the
     * folder to state.
     */
    async createFolder(name: string, folderId?: string): Promise<AssetFolder> {
      const { parentId, cacheName } = this.useCommonLibraryValues();

      const payload = {
        parentId: folderId || parentId,
        companyId: this.libraryId,
        name,
      };

      const response = await requests.post('/assets/folders', payload);
      this.updateLibraryState(response, 'folders', 'new_folder');
      // Update cache
      updateAssetsCache(cacheName, 'folders', this.folders);

      return response;
    },

    async renameAsset(payload: Asset, newName:string): Promise<void> {
      const { cacheName } = this.useCommonLibraryValues();
      const fileType = payload.mimeType.split('/')[0];

      const response = await requests.patch(
        `/assets/${payload.id}`,
        { name: newName },
        {
          cache: {
            update: {
              [cacheName]: 'delete',
              [`${cacheName}-${fileType}`]: 'delete',
            },
          },
        },
      );

      this.updateLibraryState(response, 'library', payload.id);
      // finally update the asset cache
      this.updateCache(cacheName, 'library');
      this.updateCache(`${cacheName}-${fileType}`, 'library');
    },

    async renameFolder(payload: AssetFolder, newName:string): Promise<void> {
      const { cacheName } = this.useCommonLibraryValues();

      const response = await requests.patch(
        `/assets/folders/${payload.id}`,
        { name: newName },
        {
          cache: {
            update: {
              [cacheName]: 'delete',
              [`${cacheName}-folder`]: 'delete',
            },
          },
        },
      );

      this.updateLibraryState(response, 'folders', payload.id);
      // finally update the asset cache
      this.updateCache(cacheName, 'folders');
      this.updateCache(`${cacheName}-folder`, 'folders');
    },

    /**
     * Make deletion in 'currentlySelectedAssets' if
     * item has been selected
     * @param id - Item id
     */
    deleteSpecificItem(id: string) {
      const itemIndex = this.currentlySelectedAssets.findIndex((a) => a.id === id);
      // Check if can be deleted
      if (itemIndex === -1) return;
      // Make delete action
      this.currentlySelectedAssets.splice(itemIndex, 1);
    },

    /**
     * Delet item in specific list and update the cacheName
     * according to item type.
     * @param item - Item to delete
     * @param list - List to filter
     */
    deleteItemFromList(item: AssetProp | AssetFolder, list: 'library' | 'folders') {
      const { cacheName } = this.useCommonLibraryValues();
      const itemType = list === 'library' ? (item as Asset)?.mimeType?.split('/')[0] : 'folder';
      // Find index
      const itemIndex = this[list].findIndex((i) => i.id === item.id);
      // Delete from 'currentlySelectedAssets'
      this.deleteSpecificItem(item.id);

      if (itemIndex > -1) {
        // Make list deletion
        this[list].splice(itemIndex, 1);
        // Update cache
        this.updateCache(cacheName, list);
        this.updateCache(`${cacheName}-${itemType}`, list);
      }
    },

    /**
     * Function for opening a folder, and checking if it's already
     * in the history.
     */
    setCurrentFolder(folder?: AssetFolder) {
      // if the folder is the root folder then reset the folder history
      if (!folder || folder?.id === null) {
        this.resetFolderHistory();
        return;
      }

      // check if folder is in the history
      const itemIndex = this.folderHistory.findIndex((n) => n.id === folder.id);

      // clear selected items when navigating
      this.currentlySelectedAssets = [];

      // update the current folder
      this.currentFolder = folder;

      // if the folder is already in the history, set the nested index to that folder index
      if (itemIndex !== -1) {
        this.folderHistory.length = itemIndex + 1;
        this.folderNestedIndex = itemIndex + 1;
        return;
      }

      // add the folder to the history
      this.folderHistory.push(folder);
      this.folderNestedIndex += 1;
    },

    /**
     * Delete the folder history and set nested index back to 0.
     */
    resetFolderHistory() {
      this.folderHistory.length = 0;
      this.folderNestedIndex = 0;
      this.currentFolder = { id: null };
    },

    setMoveAsset(payload: Asset[]): void {
      this.cutAssets = payload;
    },

    removeSelectedAsset(asset: Asset | AssetFolder): void {
      const assetIndex = this.currentlySelectedAssets.findIndex((i) => i.id === asset.id);

      if (assetIndex !== -1) {
        this.currentlySelectedAssets.splice(assetIndex, 1);
      }
    },

    /**
      * Function to sort assets by if they are a folder or not
      */
    sortAssetsByIsFolder(assets) {
      const assetList = [];
      const folderList = [];

      assets.forEach((asset) => {
        if ('isDirectory' in asset) {
          folderList.push(asset);
        } else {
          assetList.push(asset);
        }
      });

      return {
        assetList,
        folderList,
      };
    },

    /**
     * Make a GET request to get asset library.
     */
    async fetchAsset(id: string): Promise<any> {
      try {
        const asset = await requests.get(`/assets/${id}`);
        return asset;
      } catch {
        return Promise.resolve();
      }
    },

    /**
     * Function to select all assets between two assets when shift is pressed
     */
    shiftMultiSelectAsset(asset:Asset):void {
      // get the index of the last selected asset and current asset
      const start = this.library.findIndex((a) => a.id === this.lastSelectedAsset.id);
      const end = this.library.findIndex((a) => a.id === asset.id);

      // get all assets between the two indexes using min and max so selections can be made in any direction
      this.currentlySelectedAssets = this.library.slice(Math.min(start, end), Math.max(start, end) + 1);
      this.lastSelectedAsset = asset;
    },

    /**
     * Function to select all assets
     */
    selectAllAssets():void {
      this.currentlySelectedAssets = [...this.library];
    },

    /**
     * Helper function to cancel bulk actions.
     */
    cancelBulkActions() {
      this.setMoveAsset([]);
      this.currentlySelectedAssets = [];
    },

    /**
     * Helper function to update library state.
     */
    useCommonLibraryValues() {
      const { id } = this.currentFolder;
      const currentCompanyId = sessionStorage.getItem('cmp:company-id');
      const cacheName = `fetch-asset-library-${this.libraryId}-${id}`;

      return {
        currentCompanyId,
        parentId: id,
        cacheName,
      };
    },

    /**
     * Helper function to update library state.
     */
    updateLibraryState(response, property, id) {
      const assetIndex = this[property]
        .findIndex((i) => i.id === id);

      this[property][assetIndex] = {
        ...this[property][assetIndex],
        ...response,
      };
    },

    /**
     * Helper function to update cached library state.
     */
    updateCache(name: string, property: string) {
      const cache = window.sessionStorage.getItem(`axios-cache:${name}`);
      if (!cache) return;

      const json = JSON.parse(cache);
      json.data.data.items = this[property];
      window.sessionStorage.setItem(`axios-cache:${name}`, JSON.stringify(json));
    },

    /**
     * Make a GET request to get asset folders of
     * specific company.
     */
    async fetchFolders(parentId: string) {
      const { currentCompanyId } = this.useCommonLibraryValues();

      const result = await requests.get('/assets/library', {
        id: `fetch-asset-folders-${currentCompanyId}-${parentId}`,
        cache: { ttl: 1000 * 60, interpretHeader: false },
        params: { companies: currentCompanyId, parentId, order: 'name:asc' },
      }).catch(() => ({}));

      return result.folders || [];
    },

    async moveAsset(destinationId: string, item: Asset | AssetFolder) {
      const list = item.isDirectory ? 'folders' : 'items';
      const { cacheName } = this.useCommonLibraryValues();
      const url = `/assets${item.isDirectory ? '/folders' : ''}/${item.id}`;
      const body = item.isDirectory ? { parentId: destinationId } : { folderId: destinationId };

      await requests.patch(url, body, {
        cache: {
          update: {
            [cacheName]: (cache) => removeFromAxiosCache(cache, list, item.id),
          },
        },
      });

      // Remove items from currentParent
      if (item.isDirectory) {
        this.folders = removeItemInArray(this.folders, item.id) as AssetFolder[];
      } else {
        this.library = removeItemInArray(this.library, item.id) as Asset[];
      }
    },

    setFileType(type: FileType) {
      this.fileType = type;
    },

    /**
     * Calls 'sortItems' utility to sort 'folders'
     * and 'library' items depends on 'option' value.
     * @param option - Key variable to sort
     * @param isDesc - Is descending mode ?
     */
    sortAssets(option: string, isDesc: boolean) {
      this.folders = sortItems(this.folders, { sortBy: option, desc: isDesc });
      this.library = sortItems(this.library, { sortBy: option, desc: isDesc });
    },

    /**
     * Updates 'showEditDialog' to inverse value
     */
    toggleEditDialog() {
      this.showEditDialog = !this.showEditDialog;
    },

    // signify the start of the upload process
    // this clears out previous array's
    // !IMPORTANT this can only handle one uploade process at a time
    // otherwise array ID's and cancel process will get confused
    clearUpload() {
      loaded = [];
      uploading = [];
      failed = [];
      canceled = false;
    },

    // cancel the current upload process
    // send the cancel request for each uploaded ID
    async cancelUpload() {
      canceled = true;

      const promises = uploading.map(async (id) => {
        await requests.delete(`/assets/${id}/cancel`);
        const itemIndex = this.library.findIndex((i) => i.id === id);
        if (itemIndex !== -1) this.library.splice(itemIndex, 1);
      });

      await Promise.all(promises);

      this.clearUpload();
    },

    /**
     * Make POST request to add current asset and upload
     * the result to S3 using presigned URL.
     * @param data - Asset info (AssetUploadBody)
     * @param file - Asset file
     * @returns - Upload result
     */
    async uploadAsset(data: AssetUploadBody, file: File): Promise<AssetUploadResponse | null> {
      const preSignedResponse = await requests.post('/assets', data);
      const { id } = preSignedResponse;

      // if the upload is cancelled quickly we need to ensure that we do not continue
      if (canceled) return null;
      uploading.push(id);

      // Upload assets to S3 using presigned URL
      await axios.put(
        preSignedResponse.url,
        file,
        { headers: { 'Content-Type': file.type } },
      );

      fetchAssetWorker.postMessage({
        id,
        token: api.defaults.headers.common.Authorization,
      });

      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          const hasCompleted = loaded.includes(id);
          const hasFailed = failed.includes(id);

          if (hasCompleted || hasFailed) {
            clearInterval(interval);
          }

          if (hasCompleted) {
            loaded = loaded.filter((n) => n !== id);
            resolve(id);
          }

          if (hasFailed) {
            failed = failed.filter((n) => n !== id);
            reject(id);
          }
        }, 1000);
      });

      return preSignedResponse;
    },

    /**
     * Make a GET request to check Asset optimize progress
     * status using Asset Id param.
     * @param id - Asset id
     * @returns - Asset progress datacancelBulkAction
     */
    async checkAssetProgress(id: string): Promise<AssetOptimizeProgress> {
      return requests.get(`/assets/${id}/progress`);
    },

    /**
     * Set id to 'libraryId' state variable
     * @param libraryId - Library id
     */
    selectLibrary(libraryId: string | null) {
      // if we are open the same libraryId dont reset the folder to keep the same location from the last visit
      if (this.libraryId === libraryId) return;
      this.libraryId = libraryId;
      // Reset folder history
      this.resetFolderHistory();
      // Reset parent id
      this.currentFolder.id = null;
    },

    /**
     * Make a POST request to delete multiple asset items and
     * calls 'deleteItemFromList' to remove every item from store.
     * @param force - Force delete in cascade for folders
     */
    async deleteSelectedAssets(force = false): Promise<void | RequestError> {
      const folderContents = force ? 'DELETE' : 'IGNORE';
      const items = this.getSelectedAssetsAlphabetically.map((i) => i.id);
      // Make API request
      const response = await requests.post('/assets/bulk/delete', { items, folderContents });
      // Error in request
      if (response.message) return response;
      // Delete selected items
      this.getSelectedAssetsAlphabetically.forEach((item) => {
        const list = ('isFile' in item) ? 'library' : 'folders';
        // Call delete action
        this.deleteItemFromList(item, list);
      });

      // if there are not items reset the page number
      // stops infinite loop
      if (!this.library.length) {
        this.query.page = 1;
      }

      return response;
    },

    /**
     * Make a GET request to check if specific folder
     * has ot not content to allow delete action.
     * @param id - Folder id
     * @returns - 'isEmpty' value
     */
    async checkFolderContent(id: string): Promise<boolean> {
      const response: AssetFolderCheck = await requests.get(`/assets/folders/${id}/check`);

      return response.isEmpty;
    },
  },
});

export default useAssetsStore;
