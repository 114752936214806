import { QTooltip } from 'quasar';
import { PropType } from 'vue';
import icon from './Icon';

export type TooltipContent = Content | string;

interface Content {
  text?: string,
  captions?: Caption[],
}

interface Caption {
  type: string;
  value: string;
}

export default markRaw({
  name: 'cmpTooltip',

  props: {
    content: {
      type: [String, Object] as PropType<TooltipContent>,
    },
  },

  setup(props, { slots }) {
    return () => {
      const inner = [];

      if (typeof props.content === 'string') inner.push(props.content);

      if (typeof props.content === 'object') {
        if (props.content.text) inner.push(props.content.text);

        if (props.content.captions?.length > 0) {
          const innerChild = [];
          props.content.captions.forEach((caption) => {
            if (caption.type === 'icon') {
              innerChild.push(h(icon, { name: caption.value }));
            } else {
              innerChild.push(caption.value);
            }
          });
          inner.push(h('div', {
            class: 'cmp-tooltip-captions',
          }, innerChild));
        }
      }

      return h(
        QTooltip,
        {
          class: 'cmp-tooltip',
          offset: [4, 4],
          delay: 1000,
        },
        slots.default ? slots.default() : () => inner,
      );
    };
  },
});
