import { QBtn } from 'quasar';
import { PropType } from 'vue';
import tooltip, { TooltipContent } from './Tooltip';
import icon, { iconsMap } from './Icon';

export default markRaw({
  name: 'cmpButton',
  props: {
    class: String,
    icon: {
      type: String,
      validator: (value) => Object.keys(iconsMap).includes(value) || !value,
    },
    // the btn color (normal/disabled/activated) is set to primary version
    primaryColor: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    activated: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String || 'lg' || 'md' || 'sm',
      default: 'md',
    },
    tooltipContent: {
      type: [String, Object] as PropType<TooltipContent>,
    },
  },

  setup(props, { slots }) {
    const btnSize = computed(() => {
      switch (props.size) {
        case 'lg': return '24px'; // btn size will be 28px
        case 'md': return '20px'; // btn size will be 24px
        case 'sm': return '16px'; // btn size will be 20px
        default: return props.size;
      }
    });

    const btnClass = computed(() => {
      let classText = 'cmp-button';
      classText += props.primaryColor ? ' cmp-button--primary-color' : ' cmp-button--standard-color';
      if (props.activated && !props.disable) classText += ' cmp-button--activated';
      if (props.class) classText += ` ${props.class}`;
      return classText;
    });

    return () => {
      const child = [];
      if (slots.default) child.push(slots.default());
      if (props.icon) child.push(h(icon, { name: props.icon }));
      if (props.tooltipContent && !props.disable) child.push(h(tooltip, { content: props.tooltipContent }));
      return h(
        QBtn,
        {
          class: btnClass.value,
          disable: props.disable,
          size: btnSize.value,
          flat: true,
        },
        () => child,
      );
    };
  },
});
