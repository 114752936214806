import { defineStore } from 'pinia';
import { PlayTypes, type ILayer, IScene, IGame, IComponent, IWidget, TransformerField, ResizeStrategy } from '@cmp/common';
import { requests } from '@/common/plugins/axios';
import { Orientation } from '@/common/enums';
import { deepClone, get, objectIsEmpty } from '@/common/utils';
import { EditorMode } from '@/views/creatives/enums';
import { IPlaySettings, ICreativeAudioSettings, ICreativeGlobalTimerSettings, Creative, IApp } from '@/views/creatives/interfaces';
import { getPathFromIndex } from '@/views/creatives/utils/getPathFromIndex';
import { AssetMeta } from '@/views/assets/interfaces';
import getNewGlobalInstance from '@/views/creatives/utils/getNewGlobalInstance';
import { generateId, getArtBoardSize } from '@/views/creatives/utils';
import * as Resize from '@/views/creatives/utils/resize';
import { useCreativeStore } from './creative';
import { useEditorStore } from './editor';
import { useHistoryStore } from './history';

let timeoutId: ReturnType<typeof setTimeout>;

export type AssetSize = {
  id: string,
  base64: number,
  key: string,
  bucket: string,
  size: number,
  type: string;
  metadata: AssetMeta,
};

const getTotalLayers = (array: any[] | undefined | null) => {
  array ??= [];
  return array.reduce((prev, curr) => {
    if (!curr.children || curr.type === PlayTypes.GAME) return prev;
    return prev + getTotalLayers(curr.children);
  }, array.length);
};

function validatePath(paths, creative): string[] {
  const clone = [...paths];
  const items = clone.map((path) => get(creative, path));
  const filteredPaths = clone.filter((path, i) => !!path && !!items[i]);
  return filteredPaths.length > 0 ? filteredPaths : ['scenes[0]'];
}

function getWidgetLayerFromChildLayer(path: string) : { layer: IWidget, path: string } {
  const creativeStore = useCreativeStore();
  const layer = get(creativeStore.currentCreative, path);
  if (layer?.gameType) {
    const pathIndexes = path.match(/([0-9]+)/g) as string[];
    pathIndexes.pop();
    const newPath = getPathFromIndex(pathIndexes);
    return getWidgetLayerFromChildLayer(newPath);
  }
  if (layer?.type === PlayTypes.GAME || layer?.type === PlayTypes.COMPONENT) return { layer, path };
  return null;
}

export const usePlayCanvasStore = defineStore('playCanvas', {
  state: () => ({
    playPartners: [], // list of play partners that can be exported to
    sizes: { // all assets used in creative file sizes for playable, along with config, engine and partner code
      videos: [] as AssetSize[],
      images: [] as AssetSize[],
      fonts: [] as AssetSize[],
      audio: [] as AssetSize[],
      config: { size: 0 },
      engine: { gameCode: 0, partnerCode: 0 },
    },
    previewURI: '', // used for createPreviewLink
    muted: false, // boolean to mute playable
    previewOrientation: Orientation.PORTRAIT, // enum for preview orientation
    reloadCount: 0,
    editorMode: EditorMode.EDIT, // the setting to show the normal editor, the game edit view, or the Preview / Path View
    // showPreview: false, // boolean to show the Preview / Path View
    bulkUpdates: [], // array of updates to be  used in a bulk update after using the transform tool
    btnEffectSettings: {}, // settings for the button effect
    hugId: null,
    oldSortablePath: null, // the previous sortable parent index where the node was dragged from (scene)
    selectedPaths: [] as string[],
    editorReadySetToFit: true,
    exportVersion: '',
    videoData: [],
    propertiesPanelStates: {
      responsivity: false,
    },
    leftDrawerTab: 'layers' as 'layers' | 'assets',
    cacheEditorApps: {} as { [key: string]: IApp }, // cached apps info
    cacheZoomScale: {} as Record<string, number>, // cached zoom scale for different apps. { [appId]: zoomScale }
    cacheEditorMode: EditorMode.EDIT, // cached editor mode in editor
    disableFields: null as TransformerField[] | null,
  }),

  getters: {
    currentCreative: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative ?? {} as Creative;
    },

    scenes: () => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.scenes ?? [];
    },

    settings: (): IPlaySettings => {
      const creativeStore = useCreativeStore();
      return creativeStore.currentCreative.settings || {} as IPlaySettings;
    },

    getCurrentSelectedPath: (state): string[] => {
      if (!state.selectedPaths) return [];
      const creativeStore = useCreativeStore();

      return validatePath(state.selectedPaths, creativeStore.currentCreative).sort((a, b) => {
        for (let i = 0; i < a.length; i += 1) {
          if (a[i] !== b[i]) return +a[i] - +b[i];
        }
        return 0;
      });
    },

    getTotalLayers(): number {
      if (this.getCurrentSceneIndex === undefined || !this.scenes[this.getCurrentSceneIndex] || this.scenes[this.getCurrentSceneIndex].length === 0) return 0;
      return getTotalLayers(this.scenes[this.getCurrentSceneIndex].children);
    },

    getCurrentScene(): IScene | undefined {
      const sceneIdx = this.getCurrentSceneIndex;
      return sceneIdx >= 0 ? this.scenes[sceneIdx] : undefined;
    },

    getCurrentSceneIndex(): number {
      if (!this.getCurrentSelectedPath || this.getCurrentSelectedPath.length === 0) return undefined;
      return parseInt(this.getCurrentSelectedPath[0].match(/([0-9]+)/g)?.[0], 10);
    },

    getCurrentSelectionIndexes(): string[][] {
      if (!this.getCurrentSelectedPath) return [];
      return this.getCurrentSelectedPath.map((path) => path.match(/([0-9]+)/g));
    },

    getCurrentSelectedItems(): (ILayer | undefined)[] {
      const creativeStore = useCreativeStore();
      if (objectIsEmpty(creativeStore.currentCreative) || !this.getCurrentSelectedPath) return [{} as ILayer];
      const items = get(creativeStore.currentCreative, this.getCurrentSelectedPath);
      return Array.isArray(items) ? items : [items];
    },

    getCurrentSelectedItem(): (ILayer | undefined) {
      return this.getCurrentSelectedItems[0];
    },

    getOrientation(): Orientation {
      return this.settings.orientation as Orientation || this.settings.orientationLock as Orientation || Orientation.PORTRAIT;
    },

    getOrientationLock(): Orientation {
      return this.settings.orientationLock as Orientation || Orientation.PORTRAIT;
    },

    getActiveAppOrientation(): Orientation {
      const { activeAppId, apps } = useEditorStore();
      const appHeight = apps[activeAppId]?.artBoard?.height || 200;
      const appWidth = apps[activeAppId]?.artBoard?.width || 100;
      return appHeight >= appWidth ? Orientation.PORTRAIT : Orientation.LANDSCAPE;
    },

    getUseBGAudio(): boolean {
      return this.settings.useBGAudio ?? false;
    },

    getBGAudio(): ICreativeAudioSettings {
      return this.settings.BGAudio as ICreativeAudioSettings || {} as ICreativeAudioSettings;
    },

    getUseGlobalTimer(): boolean {
      return this.settings.useGlobalTimer ?? false;
    },

    getGlobalTimer(): ICreativeGlobalTimerSettings {
      const defaultGlobalTimerSettings: ICreativeGlobalTimerSettings = {
        onTimerFin: 'None',
        duration: 60,
        playOnEndCard: false,
        onClickUrl: '',
        iosUrl: '',
        androidUrl: '',
      };

      return this.settings.globalTimer ?? defaultGlobalTimerSettings;
    },

    getPreviewOrientation(): Orientation {
      return this.previewOrientation as Orientation;
    },

    getIsMuted(): boolean {
      return this.muted;
    },

    getCurrentSceneLength(): number {
      const sceneIndex = this.getCurrentSceneIndex;
      const creativeStore = useCreativeStore();
      if (!creativeStore.currentCreative.scenes || !creativeStore.currentCreative.scenes[sceneIndex]) return 0;
      return creativeStore.currentCreative.scenes[sceneIndex].sceneLength;
    },

    hasVideo(): boolean {
      const scene = this.scenes[this.getCurrentSceneIndex];
      if (!scene) return false;
      return scene.children.some((n: ILayer) => {
        if (n.type === PlayTypes.VIDEO) return true;
        if (n.children) return n.children.some((v: ILayer) => v.type === PlayTypes.VIDEO);
        return false;
      });
    },

    linkedLayer(): boolean {
      const scene = this.scenes[this.getCurrentSceneIndex];
      if (!scene) return false;
      const linkedLayerId = scene.linkedLayer;
      if (!linkedLayerId) return false;
      return scene.children.some((n: ILayer) => {
        if (n.type === PlayTypes.VIDEO && n.id === linkedLayerId) return true;
        if (n.children) return n.children.some((v: ILayer) => v.type === PlayTypes.VIDEO && v.id === linkedLayerId);
        return false;
      });
    },

    getCurrentSelectedWidgetsAndPaths(): { layer: IWidget, path: string }[] {
      return this.getCurrentSelectedPath.map((path) => getWidgetLayerFromChildLayer(path)).filter((widget) => !!widget);
    },

    getCurrentSelectedWidgetAndPath(): { layer: IWidget, path: string } {
      return this.getCurrentSelectedWidgetsAndPaths[0];
    },

    getCurrentSelectedWidgets(): IWidget[] {
      return this.getCurrentSelectedPath.map((path) => getWidgetLayerFromChildLayer(path)?.layer).filter((layer) => !!layer);
    },

    getCurrentSelectedWidget(): IWidget {
      return this.getCurrentSelectedWidgets[0];
    },

    getCurrentSelectedGames(): IGame[] {
      return this.getCurrentSelectedWidgets.filter((layer) => layer.type === PlayTypes.GAME) as IGame[];
    },

    getCurrentSelectedGame(): IGame {
      return this.getCurrentSelectedGames[0];
    },

    getCurrentSelectedComponents(): IComponent[] {
      return this.getCurrentSelectedWidgets.filter((layer) => layer !== null && layer.type === PlayTypes.COMPONENT) as IComponent[];
    },

    getCurrentSelectedComponent(): IComponent {
      return this.getCurrentSelectedComponents[0];
    },

    isAspectRatioLockDisabled(): boolean {
      return this.getCurrentSelectedItems.some((item) => {
        if (item?.type === PlayTypes.VIDEO) return true;
        return false;
      });
    },

    getGlobalParent() {
      const { scenes } = this;
      return (globalLayerId: string): ILayer => {
        const globalScene = scenes.find((scene) => scene.global);
        const searchArray = [...globalScene.children];

        while (searchArray.length > 0) {
          const item = searchArray.shift();
          if (item.id === globalLayerId) return item;
          if (item.children) searchArray.push(...item.children);
        }

        return null;
      };
    },

    getSceneFromPath() {
      const { scenes } = this;

      return (layerPath: string) => {
        const index = layerPath.match(/([0-9]+)/g)[0];
        return scenes[index] ?? null;
      };
    },

    getData() {
      return (data: ILayer) => {
        if (!data.globalLayerId) return data;

        const globalParent = this.getGlobalParent(data.globalLayerId);
        if (!globalParent) return data;

        const combined = { ...globalParent, ...data };
        combined.config = { ...globalParent.config, ...data.config };

        if (typeof data.startTime !== 'number') {
          const scene = this.getSceneFromPath(this.getPathFromId(data.id));
          combined.startTime = 0;
          combined.endTime = scene.sceneLength;
        }

        return combined;
      };
    },

    getGlobalParentPath() {
      const { scenes } = this;
      return (globalLayerId: string): string | null | undefined => {
        if (!globalLayerId) return null;

        let pathString = 'scenes';
        let globalScene;

        for (let i = 0; i < scenes.length; i++) {
          if (scenes[i].global) {
            globalScene = scenes[i];
            pathString += `[${i}].children`;
            break;
          }
        }

        globalScene.children.forEach((child, i) => {
          if (child.id === globalLayerId) {
            pathString += `[${i}]`;
            return;
          }
          if (child.children) {
            child.children.forEach((grandchild, j) => {
              if (grandchild.id === globalLayerId) {
                pathString += `[${i}].children[${j}]`;
              }
            });
          }
        });

        if (pathString === 'scenes') return undefined;

        return pathString;
      };
    },

    getAllChildPaths() {
      const scenes = this.scenes.filter((scene) => !scene.global);
      return (layerId: string) => {
        if (!layerId) return null;

        const paths = [];

        scenes.forEach((scene) => {
          scene.children.forEach((child) => {
            if (child.globalLayerId && child.globalLayerId === layerId) paths.push(child);
            if (child.children) {
              child.children.forEach((grandchild) => {
                if (grandchild.globalLayerId && grandchild.globalLayerId === layerId) paths.push(child);
              });
            }
          });
        });

        return paths;
      };
    },

    getPathFromId() {
      const { scenes } = this;

      return (uniqueId: string, includeGlobalScene?: boolean) => {
        let scenesArray = [...scenes];
        if (!uniqueId) return null;
        if (!includeGlobalScene) scenesArray = scenesArray.filter((scene) => !scene.global);

        let path;
        const indexOffset = includeGlobalScene ? 0 : 1;

        scenesArray.forEach((scene, i) => {
          if (scene.id === uniqueId) {
            path = `scenes[${i + indexOffset}]`;
            return;
          }

          scene.children.forEach((child, j) => {
            if (child.id === uniqueId) {
              path = `scenes[${i + indexOffset}].children[${j}]`;
              return;
            }

            if (child.children) {
              child.children.forEach((grandchild, k) => {
                if (grandchild.id === uniqueId) {
                  path = `scenes[${i + indexOffset}].children[${j}].children[${k}]`;
                }
              });
            }
          });
        });

        return path ?? null;
      };
    },

    activeURL() {
      const { settings } = this;
      return (url: string, key: string): string => {
        const nativeURL = url ?? '';
        if (settings?.usePropagateURLs && nativeURL === '') {
          return settings?.propagateURLs[key] ?? '';
        }
        return nativeURL;
      };
    },

    /**
     * Get the "tile size" calculation from the play area of a grid-based game
     * Memory has variable columns depending on orientation and calculates rows based on number of columns and tiles
     */
    getGridTileSize() {
      return (layer: IGame, width: number, height: number, orientation: Orientation) => {
        let columns;
        let rows;

        if (layer.gameId === 'memory') {
          const columnsProperty = orientation === 'Portrait' ? 'columnsPort' : 'columnsLand';
          columns = layer.properties[columnsProperty];
          rows = Math.ceil((layer.properties.tilesNum as number) / (layer.properties[columnsProperty] as number));
        } else {
          columns = layer.properties.numColumns;
          rows = layer.properties.numRows;
        }

        return Math.min(
          width / columns,
          height / rows,
        ) * ((layer.properties.tileSize as number) / 100);
      };
    },

    getLayerSize() {
      const creativeStore = useCreativeStore();
      return (ori: Orientation, path: string) => {
        const ratio = get(creativeStore.currentCreative, 'settings.aspectRatio') as string;
        const key = ori === Orientation.PORTRAIT ? 'Vert' : 'Hor';
        const boardSize = getArtBoardSize(ori, ratio);
        const matches = path.match(/([0-9]+)/g);

        if (!matches || matches.length === 0) return { width: 0, height: 0 };

        let layer = get(creativeStore.currentCreative, `scenes[${matches.shift()}]`) as IScene | ILayer;

        if (!matches || matches.length === 0) return { width: boardSize.width, height: boardSize.height };

        let { width, height } = boardSize;
        matches.forEach((match, i) => {
          if (!layer.children[match] || !layer.children[match].config) {
            width = 0;
            height = 0;
            return;
          }

          if ((layer.children[match] as ILayer).gameId === 'memory' || (layer.children[match] as ILayer).gameId === 'match3') {
            const tileSize = this.getGridTileSize(layer.children[match] as IGame, width, height, ori);
            width = tileSize;
            height = tileSize;
            if (i === matches.length - 1) return;

            layer = layer.children[match];
            return;
          }

          width *= layer.children[match].config[`width${key}`] / 100 || 0;
          height *= layer.children[match].config[`height${key}`] / 100 || 0;
          layer = layer.children[match];
        });
        return { width, height };
      };
    },

    calculateNewDimensions() {
      return (layer: ILayer, parentSize: { width: number, height: number }, ratio: number, ori: Orientation, path: string, oriKey: 'Vert' | 'Hor'): { width: number, height: number } => {
        let newWidth = 0;
        let newHeight = 0;

        if (layer.config[`width${oriKey}`] === -1) {
          switch (layer.type) {
            case PlayTypes.VIDEO:
            case PlayTypes.SEQUENCE:
            case PlayTypes.IMAGE: {
              // Define limits based on orientation
              const maxPortraitWidth = 50; // 50% max width in Portrait
              const maxPortraitHeight = 25; // 25% max height in Portrait
              const maxLandscapeWidth = 25; // 25% max width in Landscape
              const maxLandscapeHeight = 50; // 50% max height in Landscape

              // Apply limits based on orientation
              const maxWidth = ori === Orientation.LANDSCAPE ? maxLandscapeWidth : maxPortraitWidth;
              const maxHeight = ori === Orientation.LANDSCAPE ? maxLandscapeHeight : maxPortraitHeight;

              newWidth = parentSize.width * (maxWidth / 100);
              newHeight = newWidth * ratio;

              if (newHeight > parentSize.height * (maxHeight / 100)) {
                newHeight = parentSize.height * (maxHeight / 100);
                newWidth = newHeight / ratio;
              }
              break;
            }
            default:
              break;
          }
        } else {
          const { width, height } = this.getLayerSize(ori, path);
          // use general swap logic
          const longSide = Math.max(width, height);
          newHeight = ratio >= 1 ? longSide : longSide * ratio;
          newWidth = ratio >= 1 ? longSide / ratio : longSide;
        }

        // Convert to percentage of parent size
        newWidth = (newWidth / parentSize.width) * 100;
        newHeight = (newHeight / parentSize.height) * 100;

        return { width: newWidth, height: newHeight };
      };
    },

    getAssetSwapTransformUpdates() {
      const creativeStore = useCreativeStore();
      return (paths: string[], assetWidth: number, assetHeight: number, resizeStrategy: ResizeStrategy): { key: string, path: string, value: any }[] => {
        if (assetHeight === undefined || assetWidth === undefined) return [];

        const updates: { key: string, path: string, value: any }[] = [];

        paths.forEach((path) => {
          const layer: ILayer = get(creativeStore.currentCreative, path);
          const oris = [Orientation.PORTRAIT, Orientation.LANDSCAPE];
          // oriKey for looping through both portrait and landscape config
          oris.forEach((ori) => {
            const oriKey = ori === Orientation.PORTRAIT ? 'Vert' : 'Hor';
            if (!(layer.type === PlayTypes.IMAGE || layer.type === PlayTypes.SEQUENCE || layer.type === PlayTypes.VIDEO)) return;

            const ratio = assetHeight / assetWidth;
            const parentSize = this.getLayerSize(ori, path.substring(0, path.lastIndexOf('.')));

            // init sizes
            const newValues = this.calculateNewDimensions(layer, parentSize, ratio, ori, path, oriKey);

            if (resizeStrategy) {
              const params = {
                evt: {
                  width: newValues.width,
                  height: newValues.height,
                },
                orientation: oriKey as 'Hor' | 'Vert',
                parentWidth: parentSize.width,
                parentHeight: parentSize.height,
                resizeStrategy,
              };
              Resize.onAssetSwap(params);
            }

            updates.push(...[
              { value: newValues.width, key: `config.width${oriKey}`, path },
              { value: newValues.height, key: `config.height${oriKey}`, path },
            ]);
          });
        });

        return updates;
      };
    },

    getLeftDrawerTab(): string {
      if (this.leftDrawerTab.includes('layers')) return this.editorMode === EditorMode.GAME ? 'layers-widget' : 'layers-edit';
      return this.leftDrawerTab;
    },

    getLayerType(): PlayTypes {
      const currentLayer = this.getCurrentSelectedItem;
      if (!currentLayer) return null;
      return currentLayer.type;
    },
  },

  actions: {
    /**
     * Fetch partner templates for play
     */
    async fetchPlayPartners(companyId?: string) {
      const route = useRoute();
      const query = [];
      this.exportVersion = `?version=${route?.query?.export_version || 2}`;

      query.push(this.exportVersion);
      if (companyId) query.push(`companies=${companyId}`);

      const result = await requests.get(`/types/partners/play${query.join('&')}`, {
        id: 'fetch-partners-play',
      });

      this.playPartners = result.items;
    },

    getSceneByIndex(index: number | string) {
      return this.scenes[index];
    },

    /**
     * Trigger creative export
     */
    async exportPlay(id: string, partnerIds: number[], session?: string, assetEncoding = 'base64') {
      const response = await requests.post(`/creatives/${id}/export${this.exportVersion}`, {
        partnerIds,
        session,
        assetEncoding,
      });

      if (response?.status === 'IN_PROGRESS') {
        return this.exportPlay(id, partnerIds, response.session, assetEncoding);
      }

      return response;
    },

    pasteNewGlobalLayer(original: ILayer, destinationPath: string, destinationIndex: number, originalPath: string /* newParent: ILayer | IScene, newIndex?: number */) {
      const creativeStore = useCreativeStore();
      const globalClone: ILayer = deepClone(original) as ILayer;
      globalClone.globalLayer = true;
      globalClone.startTime = 0;
      globalClone.endTime = 60;

      const newParent = get(creativeStore.currentCreative, destinationPath);
      const globalContext = [...newParent.children];
      globalContext.splice(destinationIndex ?? 0, 0, globalClone);
      const path = `${this.getPathFromId(newParent.id, true)}.children`;
      this.bulkUpdate(path, globalContext);

      if (originalPath) {
        const childClone = getNewGlobalInstance(globalClone);
        childClone.id = generateId();
        this.bulkUpdate(originalPath, childClone);
      }
    },

    setSelectedPath(selectedPath: string | string[]): void {
      const newPaths = (Array.isArray(selectedPath) ? selectedPath : [selectedPath]);
      const editorStore = useEditorStore();
      const creativeStore = useCreativeStore();

      const newLength = newPaths[0].match(/([0-9]+)/g);
      const oldSceneIndex = this.getCurrentSceneIndex;

      if (editorStore.isMultiSelect) {
        this.selectedPaths = [
          ...this.selectedPaths,
          ...newPaths,
        ].reduce((prev, path) => {
          // ignore select if the new select path is not the same length as the first selected path
          // which means we can only select scene, layer and layers belong to the same object layer
          const matches = path.match(/([0-9]+)/g);
          if (newLength.length !== matches.length) return prev;
          // ignore select that already been selected
          const sceneIndex = parseInt(path.match(/([0-9]+)/g)[0], 10);
          if (this.getCurrentSceneIndex !== undefined && sceneIndex !== this.getCurrentSceneIndex) return prev;
          if (prev.includes(path)) return prev;
          return [...prev, path];
        }, []);
      } else {
        this.selectedPaths = [...newPaths];
      }

      creativeStore.showCreativeSettings = false;

      const newSceneIndex = this.getCurrentSceneIndex;
      if (newSceneIndex !== oldSceneIndex) editorStore.layers = { total: this.getTotalLayers, loaded: {} };

      editorStore.showTransformer = true;
    },

    removeSelectedPath(removedPath: string): void {
      const creativeStore = useCreativeStore();

      const newPaths = this.selectedPaths.filter((path) => path !== removedPath);
      if (newPaths.length === 0) this.setSelectedPath(`scenes[${this.getCurrentSceneIndex}]`);
      else this.selectedPaths = [...newPaths];

      creativeStore.showCreativeSettings = false;
    },

    /**
     * Create a preview link
     */
    async createPreviewLink(session?: string) {
      const creativeStore = useCreativeStore();

      const response = await requests.post(`/creatives/${creativeStore.currentCreative.id}/preview${this.exportVersion}`, {
        session,
      }).catch(() => {});
      if (!response) return null;

      if (response?.status === 'IN_PROGRESS') {
        return this.createPreviewLink(response.session);
      }

      this.previewURI = response.src;
      return this.previewURI;
    },

    /**
     * Function to bulk update values after using the transform tool
     */
    bulkUpdate(path: string, value: any): void {
      const historyStore = useHistoryStore();

      this.bulkUpdates.push({ path, value });

      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        void historyStore.execute('updateCreativeByPath', this.bulkUpdates);
        this.bulkUpdates = [];
      }, 50);
    },
  },
});

export default usePlayCanvasStore;
