import { QSeparator } from 'quasar';
import icon, { iconsMap } from './Icon';
import button from './Button';
import { TooltipContent } from './Tooltip';

export default markRaw({
  name: 'cmpModule',

  props: {
    enableDragging: {
      type: Boolean,
      default: false,
    },
    settingIcon: {
      type: String,
      default: 'tune',
      validator: (value) => Object.keys(iconsMap).includes(value) || !value,
    },
    disableSettingBtn: {
      type: Boolean,
      default: false,
    },
    settingBtnModelValue: {
      type: Boolean,
      default: false,
    },
    settingTooltip: {
      type: [String, Object] as PropType<TooltipContent>,
    },
    disableText: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
    modelValue: {
      type: String,
      default: 'Module',
    },
    hideIcon: {
      type: String,
      default: 'hidden',
      validator: (value) => Object.keys(iconsMap).includes(value) || !value,
    },
    showIcon: {
      type: String,
      default: 'visible',
      validator: (value) => Object.keys(iconsMap).includes(value) || !value,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
    removeIcon: {
      type: String,
      default: 'minus',
      validator: (value) => Object.keys(iconsMap).includes(value) || !value,
    },
    removeTooltip: {
      type: [String, Object] as PropType<TooltipContent>,
    },
  },

  emits: ['settingsClick', 'visibilityClick', 'removeClick'],
  setup(props, { slots, emit }) {
    const isSettingBtnDisabled = computed(() => (props.disableSettingBtn || props.isHidden));

    const isTextDisabled = computed(() => props.disableText || props.isHidden);

    const hideShowIcon = computed(() => (props.isHidden ? props.hideIcon : props.showIcon));

    function onSettingClick() {
      return props.settingBtnModelValue ? emit('settingsClick', false) : emit('settingsClick', true);
    }

    return () => {
      const child = [];

      // add dragging btn or add empty div for css
      if (props.enableDragging) {
        child.push(
          h('div', {
            class: 'cmp-module__drag-btn  cmp-hover-child',
            style: { 'font-size': '12px' },
          }, h(icon, {
            name: 'dragHorizontal',
          })),
        );
      } else {
        child.push(
          h('div', {
            class: 'w-[24px]',
          }),
        );
      }

      if (slots.settingPopup) child.push(slots.settingPopup({ modelValue: props.settingBtnModelValue }));

      child.push(
        h(button, {
          primaryColor: true,
          disable: isSettingBtnDisabled.value,
          activated: props.settingBtnModelValue,
          class: 'cmp-module__setting-btn',
          icon: props.settingIcon ?? 'tune',
          onClick: onSettingClick,
          tooltipContent: props.settingTooltip,
        }),
        h(QSeparator, {
          vertical: true,
          disable: props.isHidden,
        }),
        h('div', {
          class: 'flex-1',
        }, [
          h('div', {
            class: 'cmp-module__label',
            innerHTML: props.label,
            disable: isTextDisabled.value,
          }),
          h('div', {
            class: 'cmp-module__subtitle truncate',
            innerHTML: props.modelValue,
            disable: isTextDisabled.value,
          }),
        ]),
      );

      if (props.hideIcon && props.showIcon) {
        child.push(h(button, {
          class: 'cmp-module__show-btn cmp-hover-child',
          icon: hideShowIcon.value,
          tooltipContent: props.isHidden ? 'Show' : 'Hide',
          onClick: () => (props.isHidden ? emit('visibilityClick', true) : emit('visibilityClick', false)),
        }));
      }

      if (props.removeIcon) {
        child.push(h(button, {
          class: 'cmp-module__remove-btn',
          onClick: () => emit('removeClick'),
          icon: props.removeIcon,
          tooltipContent: props.removeTooltip,
        }));
      }

      return h('div', {
        class: 'cmp-module cmp-hover-parent',
      }, child);
    };
  },
});
