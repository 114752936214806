import { nanoid } from 'nanoid';
import { captureException } from '@sentry/vue';
import { AuthResponse } from '@/views/auth/interfaces';

// import { BroadcastChannel } from 'broadcast-channel';

// cross tab channel to ensure users are not logged out of another tab
// @ts-ignore
const channel = new BroadcastChannel('tab', { type: 'simulate' });
const orginalTab = nanoid();

export const signOut = (postMessage = true) => {
  // globally log out the the user across all tabs
  if (postMessage) channel.postMessage({ action: 'signout-trigger', id: orginalTab });
  window.localStorage.removeItem('cmp:auth');
  window.sessionStorage.clear();
  // if user is already on the auth screen dont redirect
  if (/^\/auth/.test(window.location.pathname)) return;
  window.location.replace(window.location.origin);
};

let timeoutId: ReturnType<typeof setTimeout>;

export const activity = (postMessage = true) => {
  try {
    // globally update the activity timeout across all tabs
    if (postMessage) channel.postMessage({ action: 'activity-trigger', id: orginalTab });
    if (timeoutId) clearTimeout(timeoutId);

    // attempt to use the expire token time
    const data = window.localStorage?.getItem('cmp:auth') || JSON.stringify({}) as string;
    const timeout = (JSON.parse(data) as AuthResponse).expiresIn || 60 * 30;

    // if the user is inactive for 30 min sign them out, same as the server
    timeoutId = setTimeout(signOut, timeout * 1000);
  } catch (error) {
    const data = window.localStorage?.getItem('cmp:auth') || JSON.stringify({}) as string;
    const { expiresIn, loggedInTime } = JSON.parse(data);
    const attachment = { data: {
      expiresIn,
      loggedInTime,
    } };
    captureException(error, attachment);
  }
};

channel.addEventListener('message', (event) => {
  const action = event.data?.action;
  const id = event.data?.id;

  if (!id) return;
  if (action === 'activity-trigger' && id !== orginalTab) activity(false);
  if (action === 'signout-trigger' && id !== orginalTab) signOut(false);
});
