{
  "CRAFTSMANPLUS": "CRAFTSMAN+",
  "CRAFTSMAN_STUDIO": "CRAFTSMAN STUDIO",
  "CRAFTSMAN_PLAY": "CRAFTSMAN PLAY",
  "CRAFTSMAN_CANVAS": "CRAFTSMAN CANVAS",
  "DELETED_COMPANIES": "Deleted Companies",
  "DELETED_USERS": "Deleted Users",
  "DELETED_DATE": "Deleted Date",
  "DELETED_BY": "Deleted By",
  "UNKNOWN": "Unkown",
  "RESTORE_COMPANY": "Restore Company",
  "RESTORE_USER": "Restore User",
  "USER": "User",
  "SCENE": "Scene",
  "GROUP": "Group",
  "VIDEO": "Video",
  "SEQUENCE": "Sequence",
  "IMAGE": "Image",
  "HOTSPOT": "Hotspot",
  "TEXT": "Text",
  "SEARCH": "Search",
  "ASSET_SEARCH": "Asset Search",
  "BACKGROUND": "Background",
  "CHANGE_COLOR": "Change Color",
  "ADVANCED": "Advanced",
  "CREATE_CUSTOM_BOARD": "Create Custom Board",
  "CREATE_CUSTOM_TEXT": "Please specify new board dimensions",
  "QUEUED": "Queued",
  "IN_PROGRESS": "In Progress",
  "COMPLETED": "Completed",
  "CANCELLED": "Cancelled",
  "CONTACT_US": "Contact Us.",
  "FAILED": "Failed",
  "BULK_MODE": "Bulk Mode",
  "INDIVIDUAL_MODE" : "Individual Mode",
  "CANVAS": {
    "SELECT_MASTER_ARTBOARD" : "Selecting Master Artboards",
    "SELECT_MASTER_DESC" : "Resizes are generated from the closest Master Artboard. Select Masters based on the expected Sizes you will create in your Project. Masters can be updated later",
    "SELECT_MASTER_WARNING":"Some active Sizes will no longer have a Master and will be disabled",
    "ACTIVE_BULKMODE_TITLE": "Active Bulk Mode",
    "ACTIVE_BULKMODE_TEXT": "Switching to Bulk Mode will synchronize layer properties across all Masters. This means that any changes you make will be reflected in all Master artboards in your project.",
    "DESIGN": "Design",
    "FRAME": "Frame",
    "INSTANCE": "Instance",
    "FRAME_VARIANT": "Frame Variant",
    "INSTANCE_VARIANT": "Instance Variant",
    "NEW": "New",
    "NEW_INSTANCE": "New Instance",
    "ADD_INSTANCE": "Add Instance",
    "NEW_SIZE": "New Size",
    "ADD_SIZE" : "Add Size ",
    "MASTERS": "Masters",
    "SIZES": "Sizes",
    "VARIANTS": "Variants",
    "LAYOUTS": "Layouts",
    "ARTBOARDS": "Artboards",
    "CUSTOM": "Custom",
    "MASTER": "Master",
    "LAYOUT": "Layout",
    "ARTBOARD": "Artboard",
    "VARIANT": "Variant",
    "DELETE_INSTANCE": "Delete Instance",
    "DELETE_FRAME": "Delete Frame",
    "DELETE_FRAMES": "Delete Frames",
    "RESET_All": "Reset All",
    "UNEXPECTED_RESULTS": "Unexpected Results?",
    "WARNINGS": {
      "IMAGE_FRAME_NEEDS_ASSET": "Please select an image asset for the frame.",
      "TEXT_NOT_FIT_FRAME": "Text does not fit within the frame.",
      "MAXIMUM_BATCHES": "Maximum 6 Batches per Instance.",
      "INVALID_FILE_TYPE": "Unsupported file type.",
      "ABOVE_MAXIMUM_FILE_SIZE": "Above Maximum File Size."
    },
    "FILTER": "Filter",
    "EDIT": "Edit",
    "ORDER": "Order",
    "EXPORT": "Export",
    "NO_EXPORTS_SELECTED": "None Selected for Export",
    "EXPORT_NEEDS_REVIEW": "One or more artboards selected for export has a text instance that needs review",
    "IMPORT": "Import",
    "LAYERS_STATUSES": "Layers Statuses",
    "LAYER_STATUS": {
      "TITLE": "Layer Status",
      "SUCCESS": "Import Successful",
      "FIXABLE": "Fixable After Import",
      "ERROR": "Error Preventing Import",
      "REMOVED": "Removed From Import"
    },
    "CANVAS_INFO" :{
      "DOUBLE_CLICK_TO_EDIT" : "Double-Click to Edit",
      "DOUBLE_CLICK_TO_RENAME" : "Double-Click to Rename",
      "ESC_TO_EXIT" : "'Esc' to Exit"
    },
    "IMPORT_INFORMATION":{
      "IMPORT_INFORMATION_TITLE":"Import Information",
      "IMPORT_INFORMATION_DESC": "Photoshop files require specific formatting in order to import into Craftsman Canvas.",
      "SEE_GUIDE": "See Guide.",
      "ARTBOARD_SIZES": "Artboard Sizes",
      "ARTBOARD_SIZES_DESC": "Recommended artboard size 1080x1080.",
      "EFFECTS_DESC":"Unsupported effects will be lost.",
      "GROUP_LAYERS":"Group Layers",
      "GROUP_LAYERS_DESC":"Groups are converted into Frames with Instances.",
      "NESTED_LAYERS":"Nested Layers",
      "NESTED_LAYERS_DESC":"Groups within groups will be unpacked.",
      "TEXT_LAYERS":"Text Layers",
      "TEXT_LAYERS_DESC":"Fonts that are not in the Asset Library will be missing.",
      "LAYER_SIZES":"Layer Sizes",
      "LAYER_SIZES_DESC":"Maximum dimensions of 4096x4096. (Including Smart Objects)",
      "MAX_FILE_SIZE":"Max File Size",
      "MAX_FILE_SIZE_DESC":"1GB Maximum. 500MB or less recommended."
    },
    "TIME_REMAINING": "Approximately {n} minute left... | Approximately {n} minutes left...",
    "UPLOAD_SUCCESSFUL": "Upload Successful.",
    "UPLOAD_FAILED": "Upload Failed.",
    "EFFECTS": {
      "SELECT": "Select Type...",
      "BLEND_MODE": "Blend Mode",
      "COLOR_OVERLAY": "Color Overlay",
      "DROP_SHADOW": "Drop Shadow",
      "INNER_GLOW": "Inner Glow",
      "OPACITY": "Opacity",
      "STROKE": "Stroke"
    },
    "GENERATE_SIZES": "Generate Sizes",
    "INITIAL_SIZE_PANEL_DESC" : "Sizes are intelligently generated from your Masters in the Design Stage. Select the Sizes you would like to generate. Sizes can be updated later.",
    "SELECTING_SIZES" : "Selecting Sizes",
    "GENERATING_SIZE_MESSAGE": "Generating {n} Sizes from your Masters",
    "GENERATING_VARIANTS_MESSAGE": "Generating Variants",
    "RESPONSIVITY_TEXT_TIP": "Text Scaling\nOccurs automatically based on the size of the new artboard."
  },
  "HIDE_TOOLTIP": {
    "SHOW": "Show",
    "HIDE": "Hide",
    "DESELECT_FOR_EXPORT": "Deselect for Export",
    "SELECT_FOR_EXPORT": "Select for Export",
    "SELECT_ALL_FOR_EXPORT": "Select All for Export",
    "DESELECT_ALL_FOR_EXPORT": "Deselect All for Export"
  },
  "DELETE_TOOLTIP": {
    "MULTIPLE_INSTANCE": "Cannot delete multiple instances",
    "LAST_INSTANCE": "Cannot delete last instance",
    "BACKGROUND_FRAME": "Background frame cannot be deleted",
    "NO_PATH": "Please select item to delete",
    "WRONG_TAB": "Please use master to delete"
  },
  "PASTE_TOOLTIP": {
    "VARIANTS": "Please use master or resize tab to paste",
    "DIFFERENT_TYPES": "Please select same type to paste properties",
    "MULTI_SELECT": "Cannot paste properties to multiple items"
  },
  "DUPLICATE_TOOLTIP": {
    "MULTIPLE_INSTANCE": "Cannot duplicate multiple instances",
    "WRONG_TAB": "Please use master to duplicate",
    "NO_PATH": "Please select item to duplicate",
    "BACKGROUND_FRAME": "Background frame cannot be duplicated"
  },
  "PRODUCT_CAPTION":{
    "PLAY": "Create playables in minutes",
    "CANVAS": "Generate static resizes and variants"
  },
  "HEIGHT": "Height",
  "WIDTH": "Width",
  "HORIZONTAL": "Horizontal",
  "VERTICAL": "Vertical",
  "LEFT": "Left",
  "RIGHT": "Right",
  "TOP": "Top",
  "BOTTOM": "Bottom",
  "CENTER": "Center",
  "POSITION": "Position",
  "FITTING": "Fitting",
  "FORGETTEN_PASSWORD": "Forgotten Password",
  "FORGOT_PASSWORD": "Forgot Password",
  "VERIFICATION_CODE": "Verification code",
  "SIGN_IN": "Sign In",
  "SYSTEM_ADMIN": "System Admin",
  "DELETE_COMPANY": "Delete Company",
  "PASSWORD": "Password",
  "COPY_LINK": "Copy Link",
  "DONE": "Done",
  "E_MAIL": "E-mail",
  "UPDATE": "Update",
  "DISCARD": "Discard",
  "TERMS_OF_SERVICE": "Terms of Services",
  "PRIVACY_POLICY": "Privacy Policy",
  "SUBMIT": "Submit",
  "CANCEL": "Cancel",
  "CLOSE": "Close",
  "ADD": "ADD",
  "ADD_BATCH": "Add Batch",
  "CREATE": "Create",
  "CONFIRM": "Confirm",
  "RESET_PASSWORD": "Reset Password",
  "RESEND_CODE": "Resend Code",
  "NEW_PASSWORD": "New Password",
  "CONFIRM_PASSWORD": "Confirm New Password",
  "COMPANY_HUB": "Company Hub",
  "ADS": "Ads",
  "CREATE_USER": "Create User",
  "DELETE_USER": "Delete User",
  "CREATION_DATE": "Creation date",
  "CREATE_GROUP": "Create Group",
  "DELETE_GROUP": "Delete Group",
  "RESTORE": "Restore",
  "THUMBNAIL": "Thumbnail",
  "AD": "Ad",
  "NEW_AD": "New Ad",
  "CREATE_AD": "Create Ad",
  "DETELE_AD": "Delete Ad",
  "DATE_ARCHIVED": "Date Archived",
  "DATE_DELETED": "Date Deleted",
  "DATE_RENEWAL": "Date of Renewal",
  "TEMPLATE": "Template",
  "EDIT": "Edit",
  "NAME": "Name",
  "TYPE": "Type",
  "FAVORITES": "Favorites",
  "AD_TEMPLATE": "Ad Template",
  "AD_TEMPLATES": "Ad Templates",
  "TEMPLATES_SUBTITLE": "Select a template to create a new project.",
  "AD_NAME": "Ad Name",
  "SOURCE": "Source",
  "SOURCE_SELECT": "Select Source",
  "CUSTOM_TEMPLATE": "Custom Ad",
  "CREATE_NEW": "Create New",
  "CREATE_FROM_TEMPLATE": "Create from template",
  "TEMPLATES_LABEL": "From Ad Template",
  "EXISTING_AD_LABEL": "From existing ad",
  "TEMPLATE_PREVIEW": "Launch Preview",
  "NO_TEMPLATE_PREVIEW": "No preview available",
  "ASSET_LIBRARY": "Asset Library",
  "ARCHIVED": "Archived",
  "DELETED": "Deleted",
  "ADMIN": "Admin",
  "PROFILE": "My Profile",
  "NEW_COMPANY": "New Company",
  "ADD_FOLDER": "Add Folder",
  "UPLOAD": "Upload",
  "UPLOADED_BY": "Uploaded By",
  "USERS": "Users",
  "USER_GROUPS": "User Groups",
  "SIGN_OUT": "Sign Out",
  "COMPANY_SEARCH": "Company Search",
  "LAST_USED": "Last Used",
  "COMPANY_NAME": "Company Name",
  "OK": "ok",
  "NO_RESULTS_FOUND": "No Results Found",
  "EXPORT": "Export",
  "DATE_CREATED": "Date Created",
  "DATE_UPDATED": "Date Updated",
  "DATE_MODIFIED": "Date Modified",
  "CREATED_BY": "Created by",
  "MODIFIED_BY": "Modified by",
  "DELETE": "Delete",
  "ARCHIVE": "Archive",
  "RENAME": "Rename",
  "SHARE": "Share",
  "MOVE": "Move",
  "ITEMS": "item | items",
  "TRANSFER": "Transfer",
  "CUT": "Cut",
  "PASTE_PROPERTIES": "Paste Properties",
  "COPY_PROPERTIES": "Copy Properties",
  "DUPLICATE": "Duplicate",
  "DOWNLOAD": "Download",
  "SHARED": "Shared",
  "TEMPLATES": "Templates",
  "LAYERS": "Layers",
  "COMPLEXITY": "Complexity",
  "GROUPS": "Groups",
  "SECTIONS": "Sections",
  "VARIANTS": "Variants",
  "CREATE_COMPLEXITY": "Create Complexity",
  "CREATE_SECTION": "Create Section",
  "ASSETS": "Assets",
  "FRAMES": "Frames",
  "PROPERTIES": "Properties",
  "FAVORITE": "Favorite",
  "UNFAVORITE": "Unfavorite",
  "FAVORITE_ADD": "Add to Favorites",
  "FAVORITE_REMOVE": "Remove from Favorites",
  "FIRST_NAME": "First Name",
  "LAST_NAME": "Last Name",
  "RETURN_SIGN_IN": "Return to Sign In",
  "EDIT_COMPANY": "Edit Company",
  "STATUS": "Status",
  "STATUS_LOGGED_IN": "Status / Last Logged In",
  "EMAIL": "Email",
  "USER_PERMISSIONS": "Module permissions",
  "CREATE_FOLDER": "Create Folder",
  "NEW_FOLDER": "New Folder",
  "NEW_GROUP": "New user group",
  "FILTER": "Filter",
  "ALPHANUMERIC": "Alphanumeric",
  "ASCENDING": "Ascending",
  "DESCENDING": "Descending",
  "NONE": "None",
  "INSIGHTS": {
    "TITLE": "Insights",
    "LAST_USED": "Days since last session",
    "SESSIONS_COUNT": "Sessions (last 30 days)",
    "LAST_SESSION": "Last session",
    "ADS_COUNT": "Ads created",
    "TOP_5": "Top 5",
    "EXPORTS_DOWNLOADED": "Exports downloaded",
    "AVERAGE_EXPORTS": "Average exports per ad"
  },
  "VALIDATION": {
    "EMAIL": "Please type your email",
    "PASSWORD": "Please type your password",
    "NEW_PASSWORD": "Please create a new password for your account",
    "REQUIRED": "Field cannot be empty",
    "PASSWORD_MATCH": "Password doesn't match",
    "COMPANY_NAME": "Company name cannot be empty",
    "GROUP_NAME": "Group name cannot be empty",
    "CANNOT_BE_EMPTY": "Value cannot be empty",
    "CREATIVE": "Ad name cannot be empty",
    "EXISTING_AD": "Ad name has the same name",
    "RESET_PASSWORD": "Please create a new password for your account",
    "TOKEN_REQUIRED": "Reset password link expired. Please re-submit reset request",
    "PASSWORD_LENGTH": "Password must be at least {length} characters long",
    "PASSWORD_UPPERCASE": "Password must contain at least 1 uppercase character",
    "PASSWORD_LOWERCASE": "Password must contain at least 1 lowercase character",
    "PASSWORD_SYMBOL": "Password cannot contain any symbols",
    "PASSWORD_NUMBER": "Password must contain at least 1 number",
    "NOT_LICENSES_ENABLED": "License information required",
    "MAX_LICENSE_USERS": "License limit reached",
    "EMPTY_FIRSTNAME": "First name cannot be empty",
    "EMPTY_LASTNAME": "Last name cannot be empty",
    "EMPTY_EMAIL": "E-mail cannot be empty",
    "EMPTY_NAME_FIELD": "Name field cannot be empty",
    "EXISTING_GROUP": "Group already exists",
    "VERIFICATION_CODE": "Please enter the verification code. This has been sent to your email address",
    "VERIFICATION_CODE_REGEX": "Verification code has 6 digits",
    "VERIFICATION_CODE_DIGITS": "Verification code must have only digits",
    "EMPTY_FOLDER_NAME": "Folder name cannot be empty",
    "EXISTING_FOLDER": "Folder already exists",
    "PLEASE_SELECT_SCENE": "Please select a scene first",
    "EMPTY_FILENAME": "Filename cannot be empty",
    "RANGE_LABEL": "Value must be between {min} and {max}",
    "SAME_FOLDER": "Cannot move item into same folder",
    "MIN_RANGE": "Minimum duration cannot be less than {value} {unit}",
    "VALID_VALUE": "Value must be {value}",
    "MAX_MACROS": "Cannot have more than 10 macros",
    "MAX_DIMENSIONS": "Exceeds 4096x4096 maximum dimensions.",
    "NOT_METADATA": "No Metadata - Re-upload Asset",
    "TOO_LARGE_ASSET": "Total Dimensions Too Large",
    "PRODUCT_INACTIVE": "You do not have an active {name} license. Please contact your account manager to get started."
   
  },
  "GREETING": {
    "MORNING": "Good Morning",
    "AFTERNOON": "Good Afternoon",
    "EVENING": "Good Evening"
  },
  "NOTIFICATION": {
    "SUCCESS_UPDATE_PASSWORD": "Password reset successfully",
    "SUCCESS_UPDATE_PROFILE": "Profile updated successfully",
    "SUCCESS_DELETE_COMPANY": "Company deleted successfully",
    "SUCCESS_RESTORE_COMPANY": "Company restored successfully",
    "SUCCESS_CREATE_COMPANY": "Company successfully created",
    "SUCCESS_UPDATE_COMPANY": "Company successfully updated",
    "SUCCESS_DELETE_USER": "User deleted successfully",
    "SUCCESS_RESTORE_USER": "User restored successfully",
    "SUCCESS_CREATE_COMPANY_USER": "User successfully created",
    "SUCCESS_UPDATE_COMPANY_USER": "User successfully updated",
    "SUCCESS_CREATE_COMPANY_GROUP": "User group successfully created",
    "SUCCESS_FAVORITES_ADD": "Added to Favorites",
    "SUCCESS_FAVORITES_REMOVE": "Removed from Favorites",
    "SUCCESS_CREATIVE_DELETE": "Ad successfully deleted",
    "SUCCESS_CREATIVE_ARCHIVE": "Ad successfully archived",
    "SUCCESS_CREATIVE_RESTORE": "Ad successfully restored",
    "SUCCESS_THUMBNAIL_UPLOADED": "Thumbnail successfully uploaded",
    "SUCCESS_CREATIVE_SHARE": "Ad successfully shared",
    "SUCCESS_CREATIVE_UPDATE": "Ad successfully updated",
    "SUCCESS_CREATIVE_DUPLICATE": "Ad successfully duplicated",
    "SUCCESS_CREATIVE_NAME_UPDATE": "Ad name updated successfully",
    "SUCCESS_FOLDER_NAME_UPDATE": "Folder name updated successfully",
    "SUCCESS_FOLDER_DELETE": "Folder successfully deleted",
    "SUCCESS_CREATIVE_CREATE": "Ad successfully created",
    "SUCCESS_FOLDER_CREATE": "Folder successfully created",
    "SUCCESS_FOLDER_MOVE": "Folder successfully moved",
    "SUCCESS_CREATIVE_MOVE": "Ad successfully moved",
    "SUCCESS_CREATIVE_TRANSFER": "Ad successfully transferred",
    "SUCCESS_RESET_REQUEST": "Reset Password request successfully sent",
    "SUCCESS_ASSET_ITEM_MOVE": "Asset file successfully moved",
    "SUCCESS_ASSET_ITEMS_MOVE": "Asset files successfully moved",
    "SUCCESS_ASSET_ITEM_RENAME": "Asset file successfully updated",
    "SUCCESS_ASSET_FOLDER_MOVE": "Asset folder successfully moved",
    "SUCCESS_ASSET_FOLDER_RENAME": "Asset folder successfully updated",
    "SUCCESS_ASSET_FOLDERS_MOVE": "Asset folders successfully moved",
    "SUCCESS_ASSETS_MOVE": "Asset successfully moved",
    "SUCCESS_TEMPLATE_DELETE": "Template successfully deleted",
    "SUCCESS_TEMPLATE_NAME_UPDATE": "Template name updated successfully",
    "INVALID_FOLDER_TO_DELETE": "Cannot delete folder with assets",
    "INVALID_FILE_SIZE": "File size is too large"
  },
  "CONFIRM_TEXTS": {
    "SIGN_OUT": "Are you sure you want to sign out?",
    "DELETE_COMPANY": "Are you sure you want to delete this company?",
    "DELETE_USER": "Are you sure you want to delete this user?",
    "RESTORE_USER": "Are you sure you want to restore this user?",
    "RESTORE_COMPANY": "Are you sure you want to restore this company?",
    "DISCARD_COMPANY_EDIT": "Are you sure you want to discard any changes made to the company?",
    "DISCARD_USER_FORM": "Are you sure you want to discard any changes made to the user form?",
    "DISCARD_AD": "Are you sure you want to permanently delete this ad?",
    "DELETE_USER_PERMANENTLY": "Are you sure you want to permanently delete this user?",
    "TRANSFER_AD": "Are you sure you want to transfer this ad?",
    "RESET_PASSWORD": "Are you sure you want to reset the user's password?",
    "DELETE_INSTANCE": "Are you sure you want to delete this instance?",
    "DELETE_FRAME_IMAGE": "Are you sure you want to delete this Image Frame?",
    "DELETE_FRAME_TEXT": "Are you sure you want to delete this Text Frame?",
    "DELETE_MULTIPLE_FRAMES": "Are you sure you want to delete these Frames?",
    "RESET_ALL_TITLE": "Reset All Artboards",
    "RESET_TITLE": "Reset Artboard",
    "RESET_PROPERTY": "Reset Property",
    "RESET_All_ARTBOARDS": "Are you sure you want to reset all artboards? Any changes made to specific sizes will be lost.",
    "RESET_ARTBOARDS": "Are you sure you want to reset this artboard? Any changes made to this size will be lost.",
    "DELETE_GAME": "Are you sure you want to delete this game?",
    "RESET_PROP_TITLE": "Reset Property",
    "RESET_PROP_DESC": "Are you sure you want to reset this property? Any changes made to this property will be lost.",
    "CREATE_CRAFTSMAN_TEMPLATE": "Are you sure you want to create a CRAFTSMAN+ Template? The Template will be added to ALL companies.",
    "DELETE_COMPANY_TEMPLATE": "Are you sure you want to delete this Template? Deletion is permanent and cannot be undone.",
    "DELETE_CRAFTSMAN_TEMPLATE": "Are you sure you want to delete this CRAFTSMAN+ Template from ALL companies? Deletion is permanent and cannot be undone.",
    "RESET_PROJECT": "Reset Project?",
    "RESET_PROJECT_DESC": "All existing layers will be deleted after using the Photoshop Import. Are you sure you want to continue?",
    "CRAFTSMAN_LIBRARY": "BE CAREFUL! You are entering the CRAFTSMAN+ Library as the System Admin. Any changes will be seen by ALL companies.",
    "DELETE_ASSET_FOLDER": "Are you sure you want to delete the folder and all contents within?"
  },
  "EMPTY": {
    "DEFAULT": "No Results",
    "ASSETS": "No Assets",
    "COMPANIES": "No company results",
    "USERS": "No users",
    "USER_GROUPS": "No user groups",
    "FOLDERS": "No folders",
    "TEMPLATES": "No Templates"
  },
  "UPLOAD_ASSETS": "Upload assets",
  "SWAP": "Swap",
  "ASSET_TYPES": "Asset types",
  "AUDIO": "Audio",
  "FONT": "Font",
  "ASSET": {
    "VIDEO_FORMATS": "File Formats MP4 or M4V.",
    "IMAGE_FORMATS": "File Formats PNG, JPEG, or WEBM.",
    "AUDIO_FORMATS": "File Formats MP3.",
    "FONT_FORMATS": "File Formats OTF or TTF."
  },
  "PLAY_ASSET_DEFAULT_NAME": {
    "ASSET": "New Asset",
    "SCENE": "New Scene",
    "IMAGE": "New Image",
    "VIDEO": "New Video"
  },
  "GAME_MECHANIC": "Game Mechanic",
  "SELECTED_ASSETS": "Selected Assets",
  "DRAG_DROP": "Drag and drop an item",
  "BROWSE_DRAG_DROP": "Click to browse or Drag and drop",
  "ITEM_CUT_TO_CLIPBOARD": "item cut to the clipboard | items cut to the clipboard",
  "VERSION": "version | versions",
  "ITEM_MOVED": "item moved | items moved",
  "ASSET_UPLOADED": "asset uploaded | assets uploaded",
  "ASSET_DELETED": "asset deleted | assets deleted",
  "TRANSFER_COMPANY": "Transfer Company",
  "GRID_VIEW": "Grid View",
  "LIST_VIEW": "List View",
  "SORT": "Sort by",
  "ZOOM": "Zoom",
  "FOLDERS": "Folders",
  "REDO": "Redo",
  "UNDO": "Undo",
  "TRANSFORM": "Transform",
  "CROP": "Crop",
  "OPTIMIZE": "Optimize",
  "UNLOCK_RATIO": "Unlock Aspect Ratio",
  "LOCK_RATIO": "Lock Aspect Ratio",
  "PRESETS": "Presets",
  "ORIGINAL": "Original",
  "SQUARE": "Square",
  "SAVE": "Save",
  "FILE_INFO": "File information",
  "PREVIEW": "Preview",
  "SAVE_AS": "Save as",
  "FILENAME": "Filename",
  "FOLDER": "Folder",
  "PLAYBACK": "Playback",
  "QUALITY": "Quality",
  "VIDEO_QUALITY": "Video Quality",
  "FILE_SETTINGS": "File settings",
  "MONO": "Mono",
  "BIT_RATE": "Bit rate",
  "RESET": "Reset",
  "EDITED_STATE": "Edited (est)",
  "SIZE": "Size",
  "PLAY": "Play",
  "PAUSE": "Pause",
  "NEXT": "Next",
  "TRIM": "Trim",
  "FRAME": "Frame",
  "TIME": "Time",
  "START": "Start",
  "END": "End",
  "MUTE": "Mute",
  "UNMUTE": "Unmute",
  "RESTART": "Restart",
  "DURATION_STATE": "Duration (frames)",
  "WEBP_CONVERT": "Convert to WebP",
  "SELECT_FOLDER": "Select folder",
  "SELECT": "Select",
  "ROOT": "Root",
  "SAVING": "Saving ...",
  "SAVED": "Saved",
  "PERCENT": "Percent",
  "EFFECTS": "Effects",
  "VERY_LOW": "Very Low",
  "LOW": "Low",
  "MEDIUM": "Medium",
  "HIGH": "High",
  "VERY_HIGH": "Very High",
  "FORMAT": "Format",
  "DIMENSIONS": "Dimensions",
  "ALIGN": "Align",
  "ALIGN_LEFT": "Align Left",
  "ALIGN_CENTER": "Align Center",
  "ALIGN_RIGHT": "Align Right",
  "ALIGN_TOP": "Align Top",
  "ALIGN_MIDDLE": "Align Middle",
  "ALIGN_BOTTOM": "Align Bottom",
  "INVALID_VIDEO_MAX_LENGTH": "Video exceeds 600s limit",
  "INVALID_VIDEO_MIN_LENGTH": "Video below 0.1s limit",
  "UNSUPPORTTED_FORMAT": "Unsupported format",
  "OPTIMIZE_IMAGE": "Use optimized image",
  "AUTO_OPTIMIZE": "Auto-Optimize",
  "SECONDS": "seconds",
  "FRAME_RATE": "Frame rate",
  "RESIZE": "Resize",
  "RESIZE_CONTROLS": "Resize Controls",
  "GENERATE_PREVIEW": "Generate preview",
  "BACK": "Back",
  "DURATION": "Duration",
  "TEXT_SETTINGS": {
    "LINE_SPACING": "Line Spacing",
    "LETTER_SPACING": "Letter Spacing",
    "LETTER_CASE": "Letter Case"
  },
  "SHADOW": "Shadow",
  "REPLACE": "Replace",
  "DESELECT_ALL": "Deselect all",
  "GAMES": "Games",
  "CREATE_GAME": "Create Game",
  "ENABLED": "Enabled",
  "DELETE_GAME": "Delete Game",
  "GAME": "Game",
  "COMPONENT": "Component",
  "USE_TEMPLATE": "Use Template",
  "NEW_TEMPLATE": "New Template",
  "CREATE_TEMPLATE": "Create Template",
  "SELECT_AD_THUMBNAIL": "Select Ad Thumbnail",
  "SELECT_AD_THUMBNAIL_MESSAGE": "Showcase your ad with an eye-catching thumbnail. Use a square image (1:1 ratio) within 512x512 pixels and under 1mb in size.",
  "CRAFTSMAN_TEMPLATES": "CRAFTSMAN+ Templates",
  "LEGACY_TEMPLATES": "Legacy Templates",
  "DELETE_TEMPLATE": "Delete Template",
  "CREATE_TEMPLATE_SUCCESS": "Template successfully created",
  "LIBRARY": "Library",
  "LIBRARIES": "Libraries",
  "SELECTED": "Selected",
  "ANALYTICS": "Analytics",
  "DATE_RANGE": "Date Range",
  "AD_NETWORKS": "Ad Networks",
  "DEVICE": "Device",
  "SYSTEM": "System",
  "COUNTRY": "Country",
  "IMPRESSIONS": "Impressions",
  "CTR": "CTR",
  "FIRST_EVENT": "First Event",
  "LAST_EVENT": "Last Event",
  "CRAFTSMAN_LIBRARY": "CRAFTSMAN+ Library",
  "PARTNERS": "Partners",
  "EXCLUDES": "Excludes",
  "INCLUDES": "Includes",
  "MAX_FILE_SIZE": "Max File Size",
  "DELETE_FOLDER": "Delete folder",
  "REMOVE": "Remove",
  "REMOVE_ALL_BATCHES": "Remove All Batches",
  "CONTINUE": "Continue",
  "DISABLED_SIZES_TITLE" : "Disabled Sizes",
  "DISABLED_SIZES_DESC" : "Some of the Sizes you've selected are linked to a Master that's currently disabled. While these Sizes will still be exported, you won't be able to edit them until their Master is reactivated",
  "MIXED": "Mixed",
  "RECENTLY_ADDED": "Recently Added",
  "UNSYNC_PROPERTIES": "Unsync Properties",
  "RESYNC_PROPERTIES": "Resync Properties",
  "ARTBOARD_SIZE": "Artboard Size",
  "CUSTOM": "Custom"
}
