import { defineStore } from 'pinia';
import { AxiosError } from 'axios';
import { useUserStore } from '@/common/store/user';
import { useCompanyStore } from '@/common/store/company';
import { useSocketStore } from '@/common/store/socket';
import { AuthPassword, AuthRequest, AuthResponse, AuthDuoChallenge, AuthChallenge, AuthChallenges, MicrosoftChallenge } from '@/views/auth/interfaces';
import { captureException } from '@sentry/vue';
import { requests, api } from '../plugins/axios';
import { signOut } from '../utils/signOut';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    confirmDialog: false,
    authChallenge: {} as AuthChallenge,
    token: '',
    loggedInTime: 0,
    expiresIn: 0,
    refreshToken: '',
    refreshTimeout: null as ReturnType<typeof setTimeout>,
    axiosResponseInterceptor: null as null | number,
  }),

  getters: {
    hasChallenge: (state) => state.authChallenge?.challenge,
  },

  actions: {
    /**
     * Reset the auth and other stores
     */
    resetAuth() {
      const userStore = useUserStore();
      const companyStore = useCompanyStore();

      if (this.refreshTimeout) clearTimeout(this.refreshTimeout);
      this.ejectInterceptors();
      userStore.$reset();
      companyStore.$reset();
      this.$reset();
      this.clearCache();
    },

    // remove the api inteceptors
    ejectInterceptors() {
      if (this.axiosResponseInterceptor === null) return;
      api.interceptors.response.eject(this.axiosResponseInterceptor);
    },

    // sign out when an api returns an 403 or 401
    errorInterceptor(error: AxiosError) {
      if ([403, 401].includes(error.response?.status) || (error?.response?.data as { error: string })?.error === 'NotAuthorizedException') signOut();
      return Promise.reject(error.response?.data);
    },

    /**
     * Remove all axios data saved in sessionStorage.
     */
    clearCache() {
      Object.keys(sessionStorage).forEach((key) => {
        if (!/^axios-cache:/.test(key)) return;
        window.sessionStorage.removeItem(key);
      });

      window.localStorage.removeItem('cmp:auth');
    },

    useResponseInterceptor() {
      // add the default response when handling un authenticated responses
      if (this.axiosResponseInterceptor !== null) return;
      this.axiosResponseInterceptor = api.interceptors.response.use(
        (axiosResponse) => axiosResponse,
        (error: AxiosError) => this.errorInterceptor(error),
      );
    },

    /**
     * Check if the user is already logged in after a refresh
     */
    async checkAuth() {
      this.useResponseInterceptor();
      this.saveAuthFromStorage();

      if (!this.token) {
        this.resetAuth();
        return false;
      }

      try {
        await this.checkAuthToken();
      } catch (_) {
        this.resetAuth();
        return false;
      }

      return !!this.token;
    },

    /**
     * Make POST request to get and save new auth data
     * sending refresh token value, and call resetAuth
     * method if request fail.
     * @param refreshToken => Refresh token value
     */
    async getRefreshToken() {
      const { refreshToken } = this;
      if (!refreshToken) return;
      const response = await requests.post('/auth/refresh', { refreshToken })
        .catch(() => {
          captureException('Failed to refresh token');
        });
      this.saveAuthToken({ ...response, refreshToken });
    },

    /**
     * Simple timer to reset the token after the current token expires
     * @param timeout : milleseconds
     */
    startRefreshTimeout(timeout: number) {
      if (!timeout) return;
      const TIMEOUT_DELAY_MS = 60 * 1000;
      const TIMEOUT_SPACE_MS = 60 * 1000;

      // Minute 1 minute to buffer timer already started on serverside
      const remainingTime = timeout - TIMEOUT_SPACE_MS;

      if (this.refreshTimeout) clearTimeout(this.refreshTimeout);

      this.refreshTimeout = setTimeout(() => {
        clearTimeout(this.refreshTimeout);
        void this.getRefreshToken();
      }, remainingTime - TIMEOUT_DELAY_MS);
    },

    saveAuthFromStorage() {
      const data = window.localStorage.getItem('cmp:auth') || JSON.stringify({});
      const { token } = JSON.parse(data);
      if (token === this.token) return;
      this.updateTokens(JSON.parse(data));
    },

    /**
     * Get the auth token from localstorage and update axios headers
     */
    async checkAuthToken() {
      const now = Date.now();
      const expiresInMs = (this.expiresIn * 1000);
      this.startRefreshTimeout(this.loggedInTime + expiresInMs - now);
      if (now < this.loggedInTime + expiresInMs) return;
      // auth token has expired
      await this.getRefreshToken();
    },

    /**
     * Save the auth challenge into state management
     * @param item => the challenge token
     */
    saveAuthChallenge(item: AuthResponse) {
      if (!item?.challenge || item.challenge !== AuthChallenges.NEW_PASSWORD_REQUIRED) return;
      this.authChallenge = item;
    },

    /**
     * Save the auth token into localstorage
     * @param item => the auth token
     */
    saveAuthToken(item: AuthResponse) {
      if (!item?.token) return;
      // expiry minus 1 minute to buffer the timer
      const expiresIn = item.expiresIn - 60;

      const storageItem = {
        ...item,
        loggedInTime: Date.now(),
      };

      window.localStorage.setItem('cmp:auth', JSON.stringify(storageItem));

      this.updateTokens(storageItem);
      this.startRefreshTimeout(expiresIn * 1000);
    },

    /**
     * Update the token in the api header and store
     */
    updateTokens(item: AuthResponse) {
      const { expiresIn = 0, refreshToken = '', token = '', loggedInTime = 0 } = item;
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.token = token;
      this.loggedInTime = loggedInTime;
      // expiry minus 1 minute to buffer the timer
      this.expiresIn = expiresIn - 60;
      this.refreshToken = refreshToken;

      if (!this.token) return;
      const socketStore = useSocketStore();
      socketStore.connect(this.token);
    },

    saveCustomAuth(item: AuthResponse) {
      if (!item) return;
      if (!item.challenge || item.challenge !== AuthChallenges.CUSTOM_CHALLENGE) return;
      if (!item.parameters.redirect) return;
      window.sessionStorage.setItem('cmp:auth-session', item.session);
      this.authChallenge = {
        session: item.session,
        challenge: item.challenge,
        redirect: item.parameters.redirect,
      };
    },

    /**
     * Make a POST request to authenticate a user
     * with specific credentials.
     * @param email => User email
     * @param password => User password
     */
    async signIn(data: AuthRequest): Promise<void> {
      // Reset authChallenge value
      this.authChallenge = {};
      // Make http request
      const response = await requests.post(
        '/auth/sign-in',
        data,
        {
          cache: {
            update: {
              'fetch-me': 'delete',
            },
          },
        },
      );

      this.saveCustomAuth(response);
      this.saveAuthChallenge(response);
      this.saveAuthToken(response);
      return response;
    },

    async duoChallenge(data: AuthDuoChallenge): Promise<void> {
      const response = await requests.post(
        '/auth/duo',
        data,
      );

      this.saveAuthToken(response);
      window.sessionStorage.removeItem('cmp:auth-session');
    },

    async microsoftChallenge(data: MicrosoftChallenge): Promise<void> {
      const response = await requests.post(
        '/auth/microsoft',
        data,
      );

      this.saveAuthToken(response);
      window.sessionStorage.removeItem('cmp:auth-session');
    },

    /**
     * Make a POST request to restore the password to
     * specific user.
     * @param email => User email
     * @returns => Request message
     */
    async restorePassword(email: string): Promise<{ message: string }> {
      const response = await requests.post('/auth/password/forgotten', { email });
      window.sessionStorage.setItem('cmp:email', email);
      return response;
    },

    /**
    * Toggle confirmDialog state to true to show/hide
    * signOut dialog.
    */
    toggleSignOutDialog(): void {
      this.confirmDialog = !this.confirmDialog;
    },

    /**
    * Reset userStore and companyStore to signOut currentUser
    * and change confirmDialog state to false.
    */
    signOut(): void {
      this.resetAuth();
      this.confirmDialog = false;
      signOut();
    },

    /**
     * Make a POST request to update user password after current
     * user make click on App link and set new password
     * @param password => New password
     * @param token => Token to allow password update
     */
    async updatePassword(data: AuthPassword) {
      await requests.post('/auth/password/reset', data);
      window.sessionStorage.removeItem('cmp:email');
    },
  },
});

export default useAuthStore;
